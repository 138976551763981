.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #fff;
  padding: 1rem;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem; /* Space between the logos */
  margin-bottom: 1rem;
}

.login-image-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-size: cover;
  background-position: center;
}

.login-logo1,
.login-logo2 {
  width: 200px;
  margin-bottom: 1rem;
  object-fit: contain;
}

.app-tagline {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: black;
}

.app-description {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  color: blueviolet;
}

.login-form-section {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  border-radius: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: linear-gradient(to right, #4e54c8, #8f94fb);
  color: white;
}

.input-wrapper {
  position: relative;
}

.input-wrapper input {
  background: transparent; /* Default background */
  transition: background 0.3s ease;
}

.input-wrapper input:focus {
   background: linear-gradient(to right, #4e54c8, #8f94fb); /* Example gradient */
  color: #fff; /* Ensure text color is visible */
  outline: none; /* Optional to remove default outline */
  border: none; /* Optional if you want no border */
  border-radius: 8px; /* Optional for smooth corners */
  padding: 10px; /* Optional for consistent spacing */
}


.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: red;
}

.text-primary {
  color: white !important;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    align-items: center;   /* Center-align the logos */
    gap: 1rem;             /* Reduce spacing between logos */
  }

  .login-image-section {
    padding: 1rem;
  }

  .login-logo1,
  .login-logo2 {
    width: 120px; /* Smaller logo size for smaller screens */
  }
  
  .login-form-section {
    padding: 1rem;
  }

  .app-tagline {
    font-size: 2rem;
  }

  .app-description {
    font-size: 1rem;
  }
}
