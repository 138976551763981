body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: azure !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainLoader{
  position : fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* AgGridCustomStyles.css */
.ag-theme-alpine .ag-paging-panel {
  text-align: center; /* Center pagination controls */
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 768px) {
  .ag-theme-alpine .ag-header-cell,
  .ag-theme-alpine .ag-cell {
    padding: 5px; /* Adjust cell padding for smaller screens */
  }
  
  .ag-theme-alpine .ag-paging-page-summary-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
     /* Hide page summary on smaller screens */
  }

  /* Adjust pagination buttons */
  .ag-theme-alpine .ag-paging-button {
    min-width: 24px; /* Ensure buttons have a minimum width */
    padding: 0 5px; /* Adjust padding for buttons */
  }

  .ag-theme-alpine .ag-paging-panel > div {
    display: flex;
    justify-content: center; /* Center the pagination controls */
    flex-wrap: wrap; /* Allow wrapping for pagination buttons */
  }
}


.highlight-cell {
    background-color: #ffcccc; /* Light red background */
    color: #000000; /* Black text color */
}
