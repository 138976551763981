.stock-entry-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.action-dropdown {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ag-grid-container {
  flex: 1; /* Take remaining vertical space */
  height: 300px; /* Initial height, adjust as needed */
}

.comments-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .stock-entry-container {
    padding: 0.5rem;
  }
}
